<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="huyThanhToan(true)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">THANH TOÁN BÁN VÉ</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>
        <ion-content :scroll-events="false">
            <div class="column container-md py-0" style="height: unset">
                <div class="text-xs-center py-3 font-bold">
                    Hướng dẫn hành khách quét camera vào mã QR
                </div>
                <div class="row justify-center pt-2">
                    <div>
                        <div
                            class="pa-2 row justify-center align-center shadow"
                            style="
                                border-radius: 7px;
                                width: 270px;
                                height: 270px;
                                position: relative;
                            "
                        >
                            <div
                                v-if="hetThoiGianThanhToan"
                                style="
                                    position: absolute;
                                    top: 40%;
                                    left: 23%;
                                    font-size: 16px;
                                    z-index: 8;
                                "
                            >
                                <div class="column justify-center">
                                    Hết thời gian thanh toán
                                    <ion-button
                                        color="primary"
                                        style="margin: 0 auto"
                                        class="mt-2"
                                        expand="block"
                                        @click="thucHienThanhToan()"
                                    >
                                        Tạo lại mã
                                    </ion-button>
                                </div>
                            </div>
                            <div
                                :style="
                                    hetThoiGianThanhToan ? 'opacity:0.1' : ''
                                "
                                class="full-width full-height"
                            >
                                <QrCodeVue
                                    v-if="ThongTinQrCode.qrCode"
                                    :data="ThongTinQrCode.qrCode"
                                    style="background-color: white"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="text-align: center"
                    :class="`pt-3 pb-2 ${color}--text font-14`"
                >
                    <span class="font-weight-regular"
                        >Thời gian thanh toán :</span
                    ><span class="font-weight-regular pl-4">{{
                        time_session
                    }}</span>
                </div>
                <div
                    class="row justify-space-around font-16 font-bold"
                    style="width: 80%; margin: 0px auto !important"
                    py-3
                >
                    <div class="pr-2">Số tiền thanh toán :</div>
                    <div>
                        {{ $MoneyFormat(ThongTinQrCode.tongTienThanhToan) }}đ
                    </div>
                </div>
                <div class="py-4">
                    <div class="container-md boundary">
                        <div class="font-14 font-bold text-xs-center">
                            Thông tin người nhận
                        </div>
                        <div class="font-12">
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Người nhận:
                                </div>
                                <div class="xs7">
                                    {{ model.NguoiNhan }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Số tài khoản nhận:
                                </div>
                                <div class="xs7">
                                    {{ model.SoTaiKhoan }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Số điện thoại:
                                </div>
                                <div class="xs7">{{ model.SoDienThoai }}</div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Ngân hàng:
                                </div>
                                <div class="xs7">{{ model.NganHang }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ====================================Popup Thông báo ========================================= -->
            <PopupVue
                height="38%"
                v-model:dialog="dialogThongBao"
                title=""
                :useButtonLeft="false"
                :resetSoDoCho="false"
                @close="dongThongBao"
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../src/components/_Common/ThanhTieuDe";
import QrCodeVue from "../../src/components/QrCode";
import DonHang from "../../class/DonHang";
import PopupVue from "../../src/components/_Common/Popup";
import ThongBaoVue from "../../src/components/_Common/ThongBao";
import { ChoNgoi } from "../../class/PhuongTien";
import { DxButton } from "devextreme-vue";
import {
    IonContent,
    IonPage,
    IonBackButton,
    IonButton,
    IonButtons,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { mapActions, mapState } from "vuex";
let checkReload;

export default {
    components: {
        ThanhTieuDeVue,
        QrCodeVue,
        PopupVue,
        ThongBaoVue,
        DxButton,
        IonContent,
        IonPage,
        IonButton,
        IonBackButton,
        IonButtons,
    },
    data() {
        return {
            model: {
                NguoiNhan: null,
                SoTaiKhoan: null,
                SoDienThoai: null,
                NganHang: null,
            },
            ThongTinQrCode: {
                tongTienThanhToan: 0,
                qrCode: "",
                thoiGianHetHanThanhToan: 300,
            },
            LoiHeThong: true,
            dialogThongBao: false,
            tempMaGiaoDich: null,
            color: "primary",
            time_session: "",
            time_remaining: 0,
            check: 0,
            qrcode: null,
            hetThoiGianThanhToan: false,
            choDaChon: null,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            back: false,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    methods: {
        ...mapActions("Ve", ["LayThongTinTaiKhoanThanhToan"]),
        timeSession(duration) {
            this.time_remaining = 0;
            let timer = duration,
                minutes,
                seconds;
            let self = this;
            if (window.timer_qr) {
                clearInterval(window.timer_qr);
            }
            window.timer_qr = setInterval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                self.time_session = minutes + ":" + seconds;
                if (minutes >= 1 && minutes <= 2) {
                    self.color = "warning";
                } else if (minutes < 1) {
                    self.color = "danger";
                } else {
                    self.color = "primary";
                }
                timer--;
                self.time_remaining = timer;
                if (self.time_remaining < 0) {
                    clearInterval(window.timer_qr);
                    return;
                }
            }, 1000);
        },
        async thucHienThanhToan() {
            try {
                this.hetThoiGianThanhToan = false;
                //Gán dữ liệu đơn hàng
                if (!this.$route.query) {
                    throw new Error();
                }
                let query = this.$route.query;
                let phatHanhVe = query.phatHanhVe == "true";
                let type = query.type;
                let soLuong = parseInt(query.soLuong);
                let donHang = new DonHang();
                donHang.hoTen = query.hoTen;
                donHang.soDienThoai = query.soDienThoai;
                donHang.email = query.email;
                //Khởi tạo thanh toán qr
                let choDaChon = this.choDaChon;

                if (
                    type == this.$t("StringCheck.KhongCoSoDoGhe") ||
                    type == this.$t("StringCheck.GhePhu")
                ) {
                    choDaChon = [];
                    for (let index = 0; index < soLuong; index++) {
                        choDaChon.push("00000000-0000-0000-0000-000000000000");
                    }
                }

                let rsThanhToan = await donHang.thanhToanQR(
                    query.idVi,
                    query.guidChuyenDi,
                    choDaChon,
                    query.guidDoanhNghiep,
                    query.maDiemXuong,
                    query.tenDiemXuong,
                    query.soDienThoai,
                    query.giaVe,
                    phatHanhVe,
                    type,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : ""
                );
                if (!rsThanhToan) {
                    throw new Error();
                }
                if (!rsThanhToan.Data.status) {
                    throw new Error(rsThanhToan.Data.message);
                }
                let dataThanhToan = rsThanhToan.Data.data;
                //Gán giá trị để hiển thị thông báo
                this.tempMaGiaoDich = dataThanhToan.maGiaoDich; //Lưu lại mã giao dịch để hủy thanh toán
                //Hiển thị popup quét mã QrCode
                let thoiGianHetHanTT =
                    new Date(dataThanhToan.thoiGianHetHanThanhToan) -
                    new Date(dataThanhToan.thoiGianHienTai);
                this.ThongTinQrCode = {
                    tongTienThanhToan: dataThanhToan.tongTienThanhToan,
                    qrCode: dataThanhToan.qrCode,
                    thoiGianHetHanThanhToan: thoiGianHetHanTT / 1000,
                };
                //Tính giờ
                this.timeSession(this.ThongTinQrCode.thoiGianHetHanThanhToan);
                //Kiểm tra thanh toán
                this.kiemTraTT(donHang, dataThanhToan);
            } catch (error) {
                this.huyThanhToan();
                this.hetThoiGianThanhToan = true;
                this.back = true;
                this.thongBaoLoi(error);
            }
        },
        async kiemTraTT(donHang, dataThanhToan) {
            clearTimeout(checkReload);
            let self = this;
            try {
                let rsKiemTraThanhToan = await donHang.kiemTraThanhToan(
                    dataThanhToan.maGiaoDich
                );
                if (!rsKiemTraThanhToan.Data.data) {
                    switch (rsKiemTraThanhToan.Data.errorCode) {
                        case 1105:
                            //Thông báo lỗi
                            self.hetThoiGianThanhToan = true;
                            break;
                        case 1103:
                            self.hetThoiGianThanhToan = true;
                            break;
                        case 1111:
                            // giải pháp lỗi timeout
                            checkReload = setTimeout(() => {
                                return self.kiemTraTT(donHang, dataThanhToan);
                            }, 1000);
                        default:
                            break;
                    }
                } else {
                    this.ThanhToanThanhCong(donHang, dataThanhToan);
                }
            } catch (error) {
                self.hetThoiGianThanhToan = true;
                this.thongBaoLoi("Kiểm tra thanh toán lỗi!");
            }
        },
        async ThanhToanThanhCong(donHang, dataThanhToan) {
            //Lấy kết kết quả giao dịch
            let rsKetQuaGiaoDich = await donHang.ketQuaGiaoDich(
                dataThanhToan.maGiaoDich
            );
            if (!rsKetQuaGiaoDich) {
                throw new Error();
            }
            //Thanh toán thành công
            this.$router.push({
                path: "thanh-toan-thanh-cong",
                query: {
                    tongTien: dataThanhToan.tongTienThanhToan,
                    soDienThoai: this.$route.query.soDienThoai,
                    tenVi: this.$route.query.tenVi,
                    MaGiaoDich: dataThanhToan.maGiaoDich,
                    CheckInVe: this.$route.query.phatHanhVe,
                },
            });
        },
        async huyThanhToan(back) {
            try {
                clearTimeout(checkReload);
                if (!this.hetThoiGianThanhToan) {
                    let huyThanhToan = await new DonHang().huyThanhToan(
                        this.tempMaGiaoDich
                    );
                    if (!huyThanhToan) {
                        this.back = true;
                        return this.thongBaoLoi();
                    }
                    if (!huyThanhToan.Data.status) {
                        this.back = true;
                        return this.thongBaoLoi(huyThanhToan.Data.message);
                    }
                }
                if (back) {
                    this.$router.go(-1);
                }
            } catch (error) {
                if (back) {
                    this.$router.go(-1);
                }
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        dongThongBao() {
            this.dialogThongBao = false;
            if (!!this.back) {
                this.$router.push({
                    path: "/tabs/tab3",
                });
                this.back = false;
            }
        },
        thongBaoLoi(message = "Đã xảy ra lỗi. Vui lòng thử lại", log) {
            this.thongBao("Error", message);
            console.log(
                "%c log ",
                "color: white; background-color: #95B46A",
                "=========>",
                log,
                message
            );
        },
        async getData() {
            try {
                let rs = await this.LayThongTinTaiKhoanThanhToan();

                if (rs.StatusCode == 200) {
                    let TTTaiKhoanThanhToan = rs.Data.data;
                    this.model.NguoiNhan = TTTaiKhoanThanhToan.nguoiNhan;
                    this.model.SoDienThoai = TTTaiKhoanThanhToan.soDienThoai;
                    this.model.SoTaiKhoan = TTTaiKhoanThanhToan.taiKhoan;
                    this.model.NganHang = TTTaiKhoanThanhToan.tenNganHang;
                }
            } catch (error) {
                console.log("🚀 ~ getData ~ error", error);
            }
        },
        closeAllDialog() {
            this.dialogThongBao = false;
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            let viTriDangChon = await new ChoNgoi().viTriDangChon();
            if (viTriDangChon) {
                this.choDaChon = viTriDangChon.map((m) => m.id);
            }
            this.thucHienThanhToan();
            this.getData();
        });
        onIonViewWillLeave(async () => {
            // this.huyThanhToan();
            clearTimeout(checkReload);
            this.closeAllDialog();
            await new DonHang().huyThanhToan(this.tempMaGiaoDich);
        });
    },
};
</script>
<style lang="scss" scoped>
</style>